.App { /* Base font style */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 13.3px;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  color: #212121;
  background-color: #f6f6f6;
}

.App .baseFont * { /* Base font style override for UI framework components */
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 13.3px;
}

.App .appBg {
  background-color: #f6f6f6;
}

.App .cardBg {
  background-color: white;
}

.App .baseTextBg { /* Base text color as background */
  background-color: #212121;
}

.App .secondaryTextColor {
  color: #3f3f3f;
}

.App .secondaryTextBg {
  background-color: #3f3f3f;
}

.App .primaryTextColor {
  color: #feecb7;
}

.App .primaryBg {
  background-color: #feecb7;
}

.App .highlightTextColor {
  color: #fec10d;
}

.App .highlightBg {
  background-color: #fec10d;
}

@media (display-mode: standalone) {
  .App {
    user-select: none;
  }
}

/* Styles to customize the UI framework plugin (MUI, https://muicss.com) */
.mui-btn {
    padding-left: 0;
    padding-right: 0;
    margin-top: 0;
    margin-bottom: 0;
}
.mui-btn--primary {
    background-color: #feecb7;
}
.mui-btn--primary:active, .mui-btn--primary:focus, .mui-btn--primary:hover {
    background-color: #feedbe;
}
.mui-btn--accent {
    background-color: #fec10d;
}
.mui-btn--accent:active, .mui-btn--accent:focus, .mui-btn--accent:hover {
    background-color: #ffc623;
}
.mui-textfield {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-checkbox, .mui-radio {  /* Reset UI framework styling that affects layout */
    margin-top: 0;
    margin-bottom: 0;
}
.mui-select {  /* Reset UI framework styling that affects layout */
    padding-top: 0;
    margin-bottom: 0;
}
.mui-btn+.mui-btn {  /* Reset UI framework styling that affects layout */
    margin-left: 0;
}
.mui-btn--fab {  /* Reset UI framework styling that breaks compositing */
    z-index: auto;
}

@keyframes fadeIn {
    0%   { opacity: 0.0; }
    100% { opacity: 1.0; }
}
@keyframes slideIn_right {
    0%   { transform: translateX(100%); }
    100% { transform: translateX(0); }
}
@keyframes slideIn_bottom {
    0%   { transform: translateY(100%); }
    100% { transform: translateY(0); }
}

/* System font classes */

.App .systemFontRegular {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
}
.App .systemFontBold {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
}
.App .systemFontItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-style: italic;
}
.App .systemFontBoldItalic {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-weight: 600;
  font-style: italic;
}

/* App-wide text styles specified in React Studio */

.App .actionFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 14.2px;
  font-weight: 400;
}
.App .actionFont * {
  font-size: 14.2px;
}
.App .headlineFont {
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 19.0px;
  font-weight: 600;
}
.App .headlineFont * {
  font-size: 19.0px;
}
.App .navBar {
  background-color: #feecb7;
  color: #0c0c0c;
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 50px;
  min-height: 50px;
  font-family: -apple-system, BlinkMacSystemFont, "Segoe UI", "Roboto", "Oxygen", "Ubuntu", sans-serif;
  font-size: 18px;
}

.App .navBar a {
  color: #0c0c0c;
}

.App .navBar .title {
  position: absolute;
  width: 100%;
  text-align: center;
  padding: 4px;
}

.App .navBar .backBtn {
  position: absolute;
  padding: 4px;
  margin-left: 14px;
  cursor: pointer;
}

.App .navBar > div {
  margin-top: 7px;
}

@media (min-width: 568px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 768px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 1024px) {
  .App .navBar {
    height: 50px;
    min-height: 50px;
  }
  .App .navBar > div {
    margin-top: 7px;
  }
}
@media (min-width: 320px) {
  .mui-container {
    max-width: 320px;
  }
}
@media (min-width: 568px) {
  .mui-container {
    max-width: 568px;
  }
}
@media (min-width: 768px) {
  .mui-container {
    max-width: 768px;
  }
}
@media (min-width: 1024px) {
  .mui-container {
    max-width: 1024px;
  }
}

.AppScreen {
  padding-left: 0;
  padding-right: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  pointer-events: auto;
}

.App .background {
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App .layoutFlow {
  position: relative;
  width: 100%;
  pointer-events: none;
}
.App .layoutFlow:before {  /* Prevent margin collapse */
  content: "";
  display: table;
  clear: both;
}
.App .layoutFlow .flowRow {
  position: relative;
  width: 100%;
}

.App .screenFgContainer {
  /* Items in the foreground group within a screen are placed with fixed positioning */
  position: fixed;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}
.App .foreground {
  position: absolute;
  left: 0;
  top: 0;
  width: 100%;
  height: 100%;
  pointer-events: none;
}

.App ul {
  margin: 0;
  padding: 0;
}
.App li {
  margin: 0;
  padding: 0;
  list-style-type: none;
  position: relative;
}
.App .hasNestedComps {
  position: relative;
}
.App .hasNestedComps:after {
  content: "";
  display: table;
  clear: both;
}
.App .hasNestedComps .background {
  position: absolute;
}
.App .hasNestedComps .layoutFlow {
  position: relative;
}
.App .bottomAlignedContent {
  position: absolute;
  bottom: 0;
  width: 100%;
}

.App .blockInputOverlay {
  position: fixed;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  pointer-events: auto;
  z-index: 99999999;
}

.App .marker {
  width: 0px;
  height: 0px;
}

.App .drawer {
  position: fixed;
  top: 0px;
  left: 0px;
  width: 100%;
  height: 100%;
}

.App .drawerOpen {
  transform: translateX(0%);
  transition: 0.3s;
}

.App .drawerClosedLeft {
  transform: translateX(-100%);
  transition: 0.3s;
}

.App .drawerClosedRight {
  transform: translateX(100%);
  transition: 0.3s;
}

.App .drawerContent {
  position: fixed;
  height: 100%;
  overflow: hidden;
}

.App .drawerContent > * {
  height: 100%;
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .HomeScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elUsername {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elUsername > * { 
  width: 300px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elUsername {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elUsername > * { 
  width: 300px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elUsername {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elUsername > * { 
  width: 298px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elUsername {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elUsername > * { 
  width: 298px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elGenderLabel {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: calc(50.0% + -125px);
  }
  .HomeScreen > .layoutFlow > .elGenderLabel > * { 
  width: 100px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elGenderLabel {
    position: relative;
    margin-top: 10px;
    width: 100px;
    margin-left: calc(50.0% + -125px);
  }
  .HomeScreen > .layoutFlow > .elGenderLabel > * { 
  width: 100px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elGenderLabel {
    position: relative;
    margin-top: 10px;
    width: 99px;
    margin-left: calc(50.0% + -124px);
  }
  .HomeScreen > .layoutFlow > .elGenderLabel > * { 
  width: 99px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elGenderLabel {
    position: relative;
    margin-top: 10px;
    width: 99px;
    margin-left: calc(50.0% + -124px);
  }
  .HomeScreen > .layoutFlow > .elGenderLabel > * { 
  width: 99px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -23px;
    width: 80px;
    margin-left: calc(50.0% + 35px);
  }
  .HomeScreen > .layoutFlow > .elPicker > * { 
  width: 80px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -23px;
    width: 80px;
    margin-left: calc(50.0% + 35px);
  }
  .HomeScreen > .layoutFlow > .elPicker > * { 
  width: 80px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -23px;
    width: 80px;
    margin-left: calc(50.0% + 35px);
  }
  .HomeScreen > .layoutFlow > .elPicker > * { 
  width: 80px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elPicker {
    position: relative;
    margin-top: -23px;
    width: 80px;
    margin-left: calc(50.0% + 35px);
  }
  .HomeScreen > .layoutFlow > .elPicker > * { 
  width: 80px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elBirthYear {
    position: relative;
    margin-top: 10px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elBirthYear > * { 
  width: 300px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elBirthYear {
    position: relative;
    margin-top: 10px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elBirthYear > * { 
  width: 300px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elBirthYear {
    position: relative;
    margin-top: 10px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elBirthYear > * { 
  width: 298px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elBirthYear {
    position: relative;
    margin-top: 10px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elBirthYear > * { 
  width: 298px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elGolfDexterity {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elGolfDexterity > * { 
  width: 300px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elGolfDexterity {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elGolfDexterity > * { 
  width: 300px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elGolfDexterity {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elGolfDexterity > * { 
  width: 298px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elGolfDexterity {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elGolfDexterity > * { 
  width: 298px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elThrowingHand {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elThrowingHand > * { 
  width: 300px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elThrowingHand {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elThrowingHand > * { 
  width: 300px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elThrowingHand {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elThrowingHand > * { 
  width: 298px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elThrowingHand {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elThrowingHand > * { 
  width: 298px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elCurrentDrivingDistance {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elCurrentDrivingDistance > * { 
  width: 300px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elCurrentDrivingDistance {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elCurrentDrivingDistance > * { 
  width: 300px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elCurrentDrivingDistance {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elCurrentDrivingDistance > * { 
  width: 298px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elCurrentDrivingDistance {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elCurrentDrivingDistance > * { 
  width: 298px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elHandicap {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elHandicap > * { 
  width: 300px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elHandicap {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elHandicap > * { 
  width: 300px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elHandicap {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elHandicap > * { 
  width: 298px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elHandicap {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elHandicap > * { 
  width: 298px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elCurrentDriverLength {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elCurrentDriverLength > * { 
  width: 300px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elCurrentDriverLength {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elCurrentDriverLength > * { 
  width: 300px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elCurrentDriverLength {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elCurrentDriverLength > * { 
  width: 298px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elCurrentDriverLength {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elCurrentDriverLength > * { 
  width: 298px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elTheStackLength {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elTheStackLength > * { 
  width: 300px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elTheStackLength {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elTheStackLength > * { 
  width: 300px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elTheStackLength {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elTheStackLength > * { 
  width: 298px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elTheStackLength {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elTheStackLength > * { 
  width: 298px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elFinishedPrograms {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elFinishedPrograms > * { 
  width: 300px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elFinishedPrograms {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .HomeScreen > .layoutFlow > .elFinishedPrograms > * { 
  width: 300px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elFinishedPrograms {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elFinishedPrograms > * { 
  width: 298px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elFinishedPrograms {
    position: relative;
    margin-top: 0px;
    width: 298px;
    margin-left: calc(50.0% + -149px);
  }
  .HomeScreen > .layoutFlow > .elFinishedPrograms > * { 
  width: 298px;
  }
  
}

.HomeScreen .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.HomeScreen .elButton button:focus {
  box-shadow: 0 0 5px rgba(255, 192, 6, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% + -72px);
  }
  .HomeScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
@media (min-width: 568px) {
  .HomeScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 145px;
    margin-left: calc(50.0% + -72px);
  }
  .HomeScreen > .layoutFlow > .elButton > * { 
  width: 145px;
  height: 35px;
  }
  
}
@media (min-width: 768px) {
  .HomeScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% + -72px);
  }
  .HomeScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}
@media (min-width: 1024px) {
  .HomeScreen > .layoutFlow > .elButton {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 144px;
    margin-left: calc(50.0% + -72px);
  }
  .HomeScreen > .layoutFlow > .elButton > * { 
  width: 144px;
  height: 35px;
  }
  
}

.HomeScreen .elMenuButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.HomeScreen .elMenuButton:focus {
  box-shadow: 0 0 5px rgba(255, 192, 6, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elMenuButton {
    position: absolute;
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elMenuButton {
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elMenuButton {
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elMenuButton {
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elTheStack {
    position: absolute;
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elTheStack {
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elTheStack {
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elTheStack {
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
}


/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elLogo {
    position: absolute;
    left: calc(50.0% + -110px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elLogo {
    left: calc(50.0% + -110px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elLogo {
    left: calc(50.0% + -109px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elLogo {
    left: calc(50.0% + -109px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
}

.circularProgressIndicator {
    position: relative;
    width: 50px;
    height: 50px;
    display: none;
}
.circularProgressIndicator-active {
    animation: rotate 2s linear infinite;
    display: inline;
}
.circularProgress-animatedPath {
    stroke-dasharray: 1,200;
    stroke-dashoffset: 0;
    animation: circularProgress-dash 1.5s ease-in-out infinite;
    stroke-linecap: round;
}
@keyframes rotate{
    100%{
    transform: rotate(360deg);
    }
}
@keyframes circularProgress-dash{
    0%{
        stroke-dasharray: 1,200;
        stroke-dashoffset: 0;
    }
    50%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -35;
    }
    100%{
        stroke-dasharray: 89,200;
        stroke-dashoffset: -124;
    }
}



/* Narrowest size for this element's responsive layout */
  .HomeScreen > .screenFgContainer > .foreground > .elProgress {
    position: absolute;
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
@media (min-width: 568px) {
  .HomeScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .HomeScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 1024px) {
  .HomeScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
}

/* This component has a fixed-size layout */
  .Name {
    width: 100%; /* This component was designed using a width of 182px */
    height: 100%;
  }


  .Name > .layoutFlow > .elText {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Name > .layoutFlow > .elText > * { 
    width: 100%;
  }
  

/* This component has a fixed-size layout */
  .ActivateLicense {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .ActivateLicense > .layoutFlow > .elTheStackLicense {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ActivateLicense > .layoutFlow > .elTheStackLicense > * { 
    width: 100%;
  }
  


  .ActivateLicense > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ActivateLicense > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 100px;
  }
  


  .ActivateLicense > .layoutFlow > .flowRow > .elLicense {
    overflow: hidden;
    position: absolute;
    margin-top: -90px;
    width: 220px;
    margin-left: calc(50.0% + -60px);
  }
  .ActivateLicense > .layoutFlow > .flowRow > .elLicense > * { 
  width: 220px;
  }
  
  .flowRow_ActivateLicense_elLicense_31400 { 
  }
  


  .ActivateLicense > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    top: -90px;
    width: 80px;
    margin-left: calc(50.0% + -140px);
  }
  .ActivateLicense > .layoutFlow > .flowRow > .elImage > * { 
  width: 80px;
  height: 80px;
  }
  


.ActivateLicense > .layoutFlow > .flowRow > .elLicenseField input {
  color: #212121;
}

.ActivateLicense > .layoutFlow > .flowRow > .elLicenseField input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.ActivateLicense > .layoutFlow > .flowRow > .elLicenseField input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .ActivateLicense > .layoutFlow > .flowRow > .elLicenseField {
    position: absolute;
    top: -65px;
    width: 150px;
    margin-left: calc(50.0% + -25px);
  }
  .ActivateLicense > .layoutFlow > .flowRow > .elLicenseField > * { 
  width: 150px;
  height: 26px;
  }
  

.ActivateLicense .elActivate button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ActivateLicense .elActivate button:focus {
  box-shadow: 0 0 5px rgba(255, 192, 6, 0.5000);
  outline: none;
}



  .ActivateLicense > .layoutFlow > .flowRow > .elActivate {
    line-height: 30px;
    position: absolute;
    top: -34px;
    width: 200px;
    margin-left: calc(50.0% + -50px);
  }
  .ActivateLicense > .layoutFlow > .flowRow > .elActivate > * { 
  width: 200px;
  height: 30px;
  }
  


  .ActivateLicense > .layoutFlow > .elActivateResult {
    position: relative;
    margin-top: 4px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .ActivateLicense > .layoutFlow > .elActivateResult > * { 
    width: 100%;
  }
  


  .ActivateLicense > .layoutFlow > .elProcess {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .ActivateLicense > .layoutFlow > .elProcess > * { 
  width: 300px;
  }
  

.ActivateLicense .elHelp button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ActivateLicense .elHelp button:focus {
  box-shadow: 0 0 6px rgba(81, 81, 81, 0.2500);
  outline: none;
}



  .ActivateLicense > .layoutFlow > .elHelp {
    position: relative;
    margin-top: 4px;
    width: 260px;
    margin-left: calc(50.0% + -130px);
  }
  .ActivateLicense > .layoutFlow > .elHelp > * { 
  width: 260px;
  height: 20px;
  }
  


  .ActivateLicense > .layoutFlow > .elCard {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .ActivateLicense > .layoutFlow > .elCard > * { 
  width: 320px;
  height: 2px;
  }
  

/* This component has a fixed-size layout */
  .GenerateSharecode {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .GenerateSharecode > .layoutFlow > .elLicensePrompt {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .GenerateSharecode > .layoutFlow > .elLicensePrompt > * { 
    width: 100%;
  }
  


  .GenerateSharecode > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .GenerateSharecode > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 100px;
  }
  


  .GenerateSharecode > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    margin-top: -90px;
    width: 80px;
    margin-left: calc(50.0% + -140px);
  }
  .GenerateSharecode > .layoutFlow > .flowRow > .elImage > * { 
  width: 80px;
  height: 80px;
  }
  
  .flowRow_GenerateSharecode_elImage_248028 { 
  }
  


  .GenerateSharecode > .layoutFlow > .flowRow > .elSharecodeLicense {
    overflow: hidden;
    position: absolute;
    top: -90px;
    width: 220px;
    margin-left: calc(50.0% + -60px);
  }
  .GenerateSharecode > .layoutFlow > .flowRow > .elSharecodeLicense > * { 
  width: 220px;
  }
  


  .GenerateSharecode > .layoutFlow > .flowRow > .elSharecode {
    overflow: hidden;
    position: absolute;
    top: -65px;
    width: 220px;
    margin-left: calc(50.0% + -60px);
  }
  .GenerateSharecode > .layoutFlow > .flowRow > .elSharecode > * { 
  width: 220px;
  }
  

.GenerateSharecode .elGenSharecode button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.GenerateSharecode .elGenSharecode button:focus {
  box-shadow: 0 0 5px rgba(255, 192, 6, 0.5000);
  outline: none;
}



  .GenerateSharecode > .layoutFlow > .flowRow > .elGenSharecode {
    line-height: 30px;
    position: absolute;
    top: -34px;
    width: 200px;
    margin-left: calc(50.0% + -50px);
  }
  .GenerateSharecode > .layoutFlow > .flowRow > .elGenSharecode > * { 
  width: 200px;
  height: 30px;
  }
  


  .GenerateSharecode > .layoutFlow > .elSharecodeResult {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .GenerateSharecode > .layoutFlow > .elSharecodeResult > * { 
    width: 100%;
  }
  


  .GenerateSharecode > .layoutFlow > .elProcess {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .GenerateSharecode > .layoutFlow > .elProcess > * { 
  width: 300px;
  }
  

.GenerateSharecode .elHelp button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.GenerateSharecode .elHelp button:focus {
  box-shadow: 0 0 6px rgba(81, 81, 81, 0.2500);
  outline: none;
}



  .GenerateSharecode > .layoutFlow > .elHelp {
    position: relative;
    margin-top: 4px;
    width: 233px;
    margin-left: calc(50.0% + -116px);
  }
  .GenerateSharecode > .layoutFlow > .elHelp > * { 
  width: 233px;
  height: 20px;
  }
  


  .GenerateSharecode > .layoutFlow > .elCard {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .GenerateSharecode > .layoutFlow > .elCard > * { 
  width: 320px;
  height: 2px;
  }
  

/* This component has a fixed-size layout */
  .Menu {
    min-height: 114px;  /* Minimum height to ensure layout flow is visible */
    width: 200px; /* This component has a fixed size */
  }
  .Menu > .background > .containerMinHeight {
    min-height: 114px;
  }

.Menu .elClose button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Menu .elClose button:focus {
  box-shadow: 0 0 6px rgba(255, 192, 6, 0.2500);
  outline: none;
}



  .Menu > .layoutFlow > .elClose {
    line-height: 40px;
    position: relative;
    margin-top: 4px;
    width: 100%;
  }
  .Menu > .layoutFlow > .elClose > * { 
  margin-left: auto;
  width: 40px;
  margin-right: 4px;
  height: 40px;
  }
  

.Menu .elHome button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Menu .elHome button:focus {
  box-shadow: 0 0 5px rgba(255, 192, 6, 0.5000);
  outline: none;
}



  .Menu > .layoutFlow > .elHome {
    line-height: 35px;
    position: relative;
    margin-top: 20px;
    width: 180px;
    margin-left: calc(50.0% + -90px);
  }
  .Menu > .layoutFlow > .elHome > * { 
  width: 180px;
  height: 35px;
  }
  

.Menu .elRegistration button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.Menu .elRegistration button:focus {
  box-shadow: 0 0 5px rgba(255, 192, 6, 0.5000);
  outline: none;
}



  .Menu > .layoutFlow > .elRegistration {
    line-height: 35px;
    position: relative;
    margin-top: 10px;
    width: 180px;
    margin-left: calc(50.0% + -90px);
  }
  .Menu > .layoutFlow > .elRegistration > * { 
  width: 180px;
  height: 35px;
  }
  

.Menu .elLogout {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Menu .elLogout:focus {
  box-shadow: 0 0 6px rgba(21, 74, 255, 0.2500);
  outline: none;
}



  .Menu > .foreground > .elLogout {
    position: absolute;
    left: calc(50.0% + -78px);
    bottom: 85px;
    width: 155px;
    height: 20px;
  }

.Menu .elTerms {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Menu .elTerms:focus {
  box-shadow: 0 0 6px rgba(21, 74, 255, 0.2500);
  outline: none;
}



  .Menu > .foreground > .elTerms {
    position: absolute;
    left: calc(50.0% + -78px);
    bottom: 24px;
    width: 155px;
    height: 20px;
  }

.Menu .elAdmin {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}



  .Menu > .foreground > .elAdmin {
    position: absolute;
    left: calc(50.0% + -90px);
    bottom: 110px;
    width: 180px;
    height: 35px;
  }

.Menu .elPrivacy {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Menu .elPrivacy:focus {
  box-shadow: 0 0 6px rgba(21, 74, 255, 0.2500);
  outline: none;
}



  .Menu > .foreground > .elPrivacy {
    position: absolute;
    left: calc(50.0% + -78px);
    bottom: 3px;
    width: 155px;
    height: 20px;
  }


  .Menu > .foreground > .elCopyright {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 60px;
    width: 100.0%;
    height: 17px;
  }


  .Menu > .foreground > .elUid {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 47px;
    width: 100.0%;
    height: 17px;
  }


  .Menu > .foreground > .elFooterLine {
    position: absolute;
    left: 10px;
    right: 10px;
    bottom: 75px;
    width: calc(100.0% - 10px - 10px);
    height: 2px;
  }

.Menu .elName {
  overflow: hidden;
}

.Menu .elName.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Menu > .foreground > .elName {
    position: absolute;
    left: 0px;
    right: 0px;
    bottom: 110px;
    width: 100.0%;
    height: 18px;
  }

/* This component has a fixed-size layout */
  .ActivateCoach {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .ActivateCoach > .layoutFlow > .elCoachesLicense {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ActivateCoach > .layoutFlow > .elCoachesLicense > * { 
    width: 100%;
  }
  


  .ActivateCoach > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .ActivateCoach > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 100px;
  }
  


  .ActivateCoach > .layoutFlow > .flowRow > .elImage {
    position: absolute;
    margin-top: -90px;
    width: 80px;
    margin-left: calc(50.0% + -140px);
  }
  .ActivateCoach > .layoutFlow > .flowRow > .elImage > * { 
  width: 80px;
  height: 80px;
  }
  
  .flowRow_ActivateCoach_elImage_395157 { 
  }
  


  .ActivateCoach > .layoutFlow > .flowRow > .elLicense {
    overflow: hidden;
    position: absolute;
    top: -90px;
    width: 220px;
    margin-left: calc(50.0% + -60px);
  }
  .ActivateCoach > .layoutFlow > .flowRow > .elLicense > * { 
  width: 220px;
  }
  


.ActivateCoach > .layoutFlow > .flowRow > .elLicenseField input {
  color: #212121;
}

.ActivateCoach > .layoutFlow > .flowRow > .elLicenseField input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.ActivateCoach > .layoutFlow > .flowRow > .elLicenseField input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .ActivateCoach > .layoutFlow > .flowRow > .elLicenseField {
    position: absolute;
    top: -65px;
    width: 150px;
    margin-left: calc(50.0% + -25px);
  }
  .ActivateCoach > .layoutFlow > .flowRow > .elLicenseField > * { 
  width: 150px;
  height: 26px;
  }
  

.ActivateCoach .elActivate button {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 3.4px;
}

.ActivateCoach .elActivate button:focus {
  box-shadow: 0 0 5px rgba(255, 192, 6, 0.5000);
  outline: none;
}



  .ActivateCoach > .layoutFlow > .flowRow > .elActivate {
    line-height: 30px;
    position: absolute;
    top: -34px;
    width: 200px;
    margin-left: calc(50.0% + -50px);
  }
  .ActivateCoach > .layoutFlow > .flowRow > .elActivate > * { 
  width: 200px;
  height: 30px;
  }
  


  .ActivateCoach > .layoutFlow > .elActivateResult {
    position: relative;
    margin-top: 0px;
    margin-left: 10px;
    margin-right: 10px;
    width: calc(100.0% - 20px);
  }
  .ActivateCoach > .layoutFlow > .elActivateResult > * { 
    width: 100%;
  }
  


  .ActivateCoach > .layoutFlow > .elProcess {
    position: relative;
    margin-top: 0px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .ActivateCoach > .layoutFlow > .elProcess > * { 
  width: 300px;
  }
  

.ActivateCoach .elHelp button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.ActivateCoach .elHelp button:focus {
  box-shadow: 0 0 6px rgba(81, 81, 81, 0.2500);
  outline: none;
}



  .ActivateCoach > .layoutFlow > .elHelp {
    position: relative;
    margin-top: 4px;
    width: 250px;
    margin-left: calc(50.0% + -125px);
  }
  .ActivateCoach > .layoutFlow > .elHelp > * { 
  width: 250px;
  height: 20px;
  }
  


  .ActivateCoach > .layoutFlow > .elCard {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .ActivateCoach > .layoutFlow > .elCard > * { 
  width: 320px;
  height: 2px;
  }
  

/* This component has a fixed-size layout */
  .TheStackApp {
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }


  .TheStackApp > .layoutFlow > .elTheStackLicense {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    width: 300px;
    margin-left: calc(50.0% + -150px);
  }
  .TheStackApp > .layoutFlow > .elTheStackLicense > * { 
  width: 300px;
  }
  


  .TheStackApp > .layoutFlow > .elImage {
    position: relative;
    margin-top: 10px;
    width: 120px;
    margin-left: calc(50.0% + -60px);
  }
  .TheStackApp > .layoutFlow > .elImage > * { 
  width: 120px;
  height: 40px;
  }
  


  .TheStackApp > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    width: 10px;
    margin-left: calc(50.0% + -5px);
  }
  .TheStackApp > .layoutFlow > .elSpacer > * { 
  width: 10px;
  height: 10px;
  }
  


/* Narrowest size for this element's responsive layout */
  .RegistrationScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .RegistrationScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .RegistrationScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .RegistrationScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .RegistrationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .RegistrationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .RegistrationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .RegistrationScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
}

.RegistrationScreen .elActivateLicense {
  overflow: hidden;
}

.RegistrationScreen .elActivateLicense.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .RegistrationScreen > .layoutFlow > .elActivateLicense {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elActivateLicense > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elActivateLicense > .background > .containerMinHeight {
    min-height: 265px;
  }

@media (min-width: 568px) {
  .RegistrationScreen > .layoutFlow > .elActivateLicense {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elActivateLicense > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elActivateLicense > .background > .containerMinHeight {
    min-height: 265px;
  }

}
@media (min-width: 768px) {
  .RegistrationScreen > .layoutFlow > .elActivateLicense {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elActivateLicense > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elActivateLicense > .background > .containerMinHeight {
    min-height: 264px;
  }

}
@media (min-width: 1024px) {
  .RegistrationScreen > .layoutFlow > .elActivateLicense {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elActivateLicense > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elActivateLicense > .background > .containerMinHeight {
    min-height: 264px;
  }

}

.RegistrationScreen .elGenerateSharecode {
  overflow: hidden;
}

.RegistrationScreen .elGenerateSharecode.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode > .background > .containerMinHeight {
    min-height: 277px;
  }

@media (min-width: 568px) {
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode > .background > .containerMinHeight {
    min-height: 277px;
  }

}
@media (min-width: 768px) {
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode > .background > .containerMinHeight {
    min-height: 275px;
  }

}
@media (min-width: 1024px) {
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elGenerateSharecode > .background > .containerMinHeight {
    min-height: 275px;
  }

}

.RegistrationScreen .elActivateCoach {
  overflow: hidden;
}

.RegistrationScreen .elActivateCoach.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .RegistrationScreen > .layoutFlow > .elActivateCoach {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elActivateCoach > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elActivateCoach > .background > .containerMinHeight {
    min-height: 277px;
  }

@media (min-width: 568px) {
  .RegistrationScreen > .layoutFlow > .elActivateCoach {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elActivateCoach > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elActivateCoach > .background > .containerMinHeight {
    min-height: 277px;
  }

}
@media (min-width: 768px) {
  .RegistrationScreen > .layoutFlow > .elActivateCoach {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elActivateCoach > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elActivateCoach > .background > .containerMinHeight {
    min-height: 275px;
  }

}
@media (min-width: 1024px) {
  .RegistrationScreen > .layoutFlow > .elActivateCoach {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .RegistrationScreen > .layoutFlow > .elActivateCoach > * { 
    width: 100%;
  }
  
  .RegistrationScreen > .layoutFlow > .elActivateCoach > .background > .containerMinHeight {
    min-height: 275px;
  }

}

.RegistrationScreen .elTheStackApp {
  overflow: hidden;
}

.RegistrationScreen .elTheStackApp.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .RegistrationScreen > .layoutFlow > .elTheStackApp {
    position: relative;
    margin-top: 4px;
    width: 182px;
    margin-left: calc(50.0% + -91px);
  }
  .RegistrationScreen > .layoutFlow > .elTheStackApp > * { 
  width: 182px;
  }
  
  .RegistrationScreen > .layoutFlow > .elTheStackApp > .background > .containerMinHeight {
    min-height: 91px;
  }

@media (min-width: 568px) {
  .RegistrationScreen > .layoutFlow > .elTheStackApp {
    position: relative;
    margin-top: 4px;
    width: 182px;
    margin-left: calc(50.0% + -91px);
  }
  .RegistrationScreen > .layoutFlow > .elTheStackApp > * { 
  width: 182px;
  }
  
  .RegistrationScreen > .layoutFlow > .elTheStackApp > .background > .containerMinHeight {
    min-height: 91px;
  }

}
@media (min-width: 768px) {
  .RegistrationScreen > .layoutFlow > .elTheStackApp {
    position: relative;
    margin-top: 4px;
    width: 180px;
    margin-left: calc(50.0% + -90px);
  }
  .RegistrationScreen > .layoutFlow > .elTheStackApp > * { 
  width: 180px;
  }
  
  .RegistrationScreen > .layoutFlow > .elTheStackApp > .background > .containerMinHeight {
    min-height: 91px;
  }

}
@media (min-width: 1024px) {
  .RegistrationScreen > .layoutFlow > .elTheStackApp {
    position: relative;
    margin-top: 4px;
    width: 180px;
    margin-left: calc(50.0% + -90px);
  }
  .RegistrationScreen > .layoutFlow > .elTheStackApp > * { 
  width: 180px;
  }
  
  .RegistrationScreen > .layoutFlow > .elTheStackApp > .background > .containerMinHeight {
    min-height: 91px;
  }

}

.RegistrationScreen .elMenuButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.RegistrationScreen .elMenuButton:focus {
  box-shadow: 0 0 5px rgba(255, 192, 6, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .RegistrationScreen > .screenFgContainer > .foreground > .elMenuButton {
    position: absolute;
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elMenuButton {
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elMenuButton {
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elMenuButton {
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
}


/* Narrowest size for this element's responsive layout */
  .RegistrationScreen > .screenFgContainer > .foreground > .elTheStack {
    position: absolute;
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
@media (min-width: 568px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elTheStack {
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
}
@media (min-width: 768px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elTheStack {
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
}
@media (min-width: 1024px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elTheStack {
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
}


/* Narrowest size for this element's responsive layout */
  .RegistrationScreen > .screenFgContainer > .foreground > .elLogo {
    position: absolute;
    left: calc(50.0% + -110px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elLogo {
    left: calc(50.0% + -110px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elLogo {
    left: calc(50.0% + -109px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elLogo {
    left: calc(50.0% + -109px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .RegistrationScreen > .screenFgContainer > .foreground > .elProgress {
    position: absolute;
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
@media (min-width: 568px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 1024px) {
  .RegistrationScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .LoginScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .layoutFlow > .elTheStack {
    position: relative;
    margin-top: 40px;
    width: 233px;
    margin-left: calc(50.0% + -116px);
  }
  .LoginScreen > .layoutFlow > .elTheStack > * { 
  width: 233px;
  }
  
@media (min-width: 568px) {
  .LoginScreen > .layoutFlow > .elTheStack {
    position: relative;
    margin-top: 40px;
    width: 233px;
    margin-left: calc(50.0% + -116px);
  }
  .LoginScreen > .layoutFlow > .elTheStack > * { 
  width: 233px;
  }
  
}
@media (min-width: 768px) {
  .LoginScreen > .layoutFlow > .elTheStack {
    position: relative;
    margin-top: 40px;
    width: 232px;
    margin-left: calc(50.0% + -116px);
  }
  .LoginScreen > .layoutFlow > .elTheStack > * { 
  width: 232px;
  }
  
}
@media (min-width: 1024px) {
  .LoginScreen > .layoutFlow > .elTheStack {
    position: relative;
    margin-top: 40px;
    width: 232px;
    margin-left: calc(50.0% + -116px);
  }
  .LoginScreen > .layoutFlow > .elTheStack > * { 
  width: 232px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .layoutFlow > .elTheStackLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: calc(50.0% + -40px);
  }
  .LoginScreen > .layoutFlow > .elTheStackLogo > * { 
  width: 80px;
  }
  
@media (min-width: 568px) {
  .LoginScreen > .layoutFlow > .elTheStackLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: calc(50.0% + -40px);
  }
  .LoginScreen > .layoutFlow > .elTheStackLogo > * { 
  width: 80px;
  }
  
}
@media (min-width: 768px) {
  .LoginScreen > .layoutFlow > .elTheStackLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: calc(50.0% + -40px);
  }
  .LoginScreen > .layoutFlow > .elTheStackLogo > * { 
  width: 80px;
  }
  
}
@media (min-width: 1024px) {
  .LoginScreen > .layoutFlow > .elTheStackLogo {
    position: relative;
    margin-top: 20px;
    width: 80px;
    margin-left: calc(50.0% + -40px);
  }
  .LoginScreen > .layoutFlow > .elTheStackLogo > * { 
  width: 80px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
@media (min-width: 568px) {
  .LoginScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 768px) {
  .LoginScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}
@media (min-width: 1024px) {
  .LoginScreen > .layoutFlow > .elText2 {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elText2 > * { 
    width: 100%;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .layoutFlow > .elLogin {
    position: relative;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elLogin > * { 
    width: 100%;
  height: 170px;
  }
  
@media (min-width: 568px) {
  .LoginScreen > .layoutFlow > .elLogin {
    position: relative;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elLogin > * { 
    width: 100%;
  height: 170px;
  }
  
}
@media (min-width: 768px) {
  .LoginScreen > .layoutFlow > .elLogin {
    position: relative;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elLogin > * { 
    width: 100%;
  height: 169px;
  }
  
}
@media (min-width: 1024px) {
  .LoginScreen > .layoutFlow > .elLogin {
    position: relative;
    margin-top: 20px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .LoginScreen > .layoutFlow > .elLogin > * { 
    width: 100%;
  height: 169px;
  }
  
}

.LoginScreen .elTerms {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.LoginScreen .elTerms:focus {
  box-shadow: 0 0 6px rgba(21, 74, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elTerms {
    position: absolute;
    left: calc(50.0% + 15px);
    top: calc(-30px + 100.0%);
    width: 155px;
    height: 20px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elTerms {
    left: calc(50.0% + 15px);
    top: calc(-30px + 100.0%);
    width: 155px;
    height: 20px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elTerms {
    left: calc(50.0% + 15px);
    top: calc(-30px + 100.0%);
    width: 154px;
    height: 20px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elTerms {
    left: calc(50.0% + 15px);
    top: calc(-30px + 100.0%);
    width: 154px;
    height: 20px;
  }
}

.LoginScreen .elPrivacy {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.LoginScreen .elPrivacy:focus {
  box-shadow: 0 0 6px rgba(21, 74, 255, 0.2500);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elPrivacy {
    position: absolute;
    left: calc(50.0% + -160px);
    top: calc(-30px + 100.0%);
    width: 155px;
    height: 20px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elPrivacy {
    left: calc(50.0% + -160px);
    top: calc(-30px + 100.0%);
    width: 155px;
    height: 20px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elPrivacy {
    left: calc(50.0% + -159px);
    top: calc(-30px + 100.0%);
    width: 154px;
    height: 20px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elPrivacy {
    left: calc(50.0% + -159px);
    top: calc(-30px + 100.0%);
    width: 154px;
    height: 20px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elText {
    overflow: hidden;
    position: absolute;
    left: 0px;
    right: 0px;
    top: calc(-55px + 100.0%);
    width: 100.0%;
    height: 23px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elText {
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: calc(-55px + 100.0%);
    width: 100.0%;
    height: 23px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elText {
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: calc(-55px + 100.0%);
    width: 100.0%;
    height: 23px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elText {
    overflow: hidden;
    left: 0px;
    right: 0px;
    top: calc(-55px + 100.0%);
    width: 100.0%;
    height: 23px;
  }
}


/* Narrowest size for this element's responsive layout */
  .LoginScreen > .screenFgContainer > .foreground > .elFooterLine {
    position: fixed;
    left: 10px;
    right: 10px;
    bottom: 60px;
    width: calc(100.0% - 10px - 10px);
    height: 2px;
  }
@media (min-width: 568px) {
  .LoginScreen > .screenFgContainer > .foreground > .elFooterLine {
    left: 10px;
    right: 10px;
    bottom: 60px;
    width: calc(100.0% - 10px - 10px);
    height: 2px;
  }
}
@media (min-width: 768px) {
  .LoginScreen > .screenFgContainer > .foreground > .elFooterLine {
    left: 10px;
    right: 10px;
    bottom: 60px;
    width: calc(100.0% - 10px - 10px);
    height: 2px;
  }
}
@media (min-width: 1024px) {
  .LoginScreen > .screenFgContainer > .foreground > .elFooterLine {
    left: 10px;
    right: 10px;
    bottom: 60px;
    width: calc(100.0% - 10px - 10px);
    height: 2px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AdminScreen > .background > .elBackground {
    position: absolute;
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
@media (min-width: 568px) {
  .AdminScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 768px) {
  .AdminScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}
@media (min-width: 1024px) {
  .AdminScreen > .background > .elBackground {
    left: 0px;
    right: 0px;
    top: 0px;
    width: 100.0%;
    height: 100%;
  }
}


/* Narrowest size for this element's responsive layout */
  .AdminScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AdminScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
@media (min-width: 568px) {
  .AdminScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AdminScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
}
@media (min-width: 768px) {
  .AdminScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AdminScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
}
@media (min-width: 1024px) {
  .AdminScreen > .layoutFlow > .elSpacer {
    position: relative;
    margin-top: 0px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .AdminScreen > .layoutFlow > .elSpacer > * { 
    width: 100%;
  height: 50px;
  }
  
}


/* Narrowest size for this element's responsive layout */
  .AdminScreen > .layoutFlow > .elSelector {
    position: relative;
    margin-top: 10px;
    width: 187px;
    margin-left: calc(50.0% + -93px);
  }
  .AdminScreen > .layoutFlow > .elSelector > * { 
  width: 187px;
  }
  
@media (min-width: 568px) {
  .AdminScreen > .layoutFlow > .elSelector {
    position: relative;
    margin-top: 10px;
    width: 187px;
    margin-left: calc(50.0% + -93px);
  }
  .AdminScreen > .layoutFlow > .elSelector > * { 
  width: 187px;
  }
  
}
@media (min-width: 768px) {
  .AdminScreen > .layoutFlow > .elSelector {
    position: relative;
    margin-top: 10px;
    width: 186px;
    margin-left: calc(50.0% + -93px);
  }
  .AdminScreen > .layoutFlow > .elSelector > * { 
  width: 186px;
  }
  
}
@media (min-width: 1024px) {
  .AdminScreen > .layoutFlow > .elSelector {
    position: relative;
    margin-top: 10px;
    width: 186px;
    margin-left: calc(50.0% + -93px);
  }
  .AdminScreen > .layoutFlow > .elSelector > * { 
  width: 186px;
  }
  
}

.AdminScreen .elLicenses {
  overflow: hidden;
}

.AdminScreen .elLicenses.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



/* Narrowest size for this element's responsive layout */
  .AdminScreen > .layoutFlow > .elLicenses {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .AdminScreen > .layoutFlow > .elLicenses > * { 
  width: 320px;
  }
  
  .AdminScreen > .layoutFlow > .elLicenses > .background > .containerMinHeight {
    min-height: 462px;
  }

@media (min-width: 568px) {
  .AdminScreen > .layoutFlow > .elLicenses {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .AdminScreen > .layoutFlow > .elLicenses > * { 
  width: 320px;
  }
  
  .AdminScreen > .layoutFlow > .elLicenses > .background > .containerMinHeight {
    min-height: 462px;
  }

}
@media (min-width: 768px) {
  .AdminScreen > .layoutFlow > .elLicenses {
    position: relative;
    margin-top: 0px;
    width: 318px;
    margin-left: calc(50.0% + -159px);
  }
  .AdminScreen > .layoutFlow > .elLicenses > * { 
  width: 318px;
  }
  
  .AdminScreen > .layoutFlow > .elLicenses > .background > .containerMinHeight {
    min-height: 460px;
  }

}
@media (min-width: 1024px) {
  .AdminScreen > .layoutFlow > .elLicenses {
    position: relative;
    margin-top: 0px;
    width: 318px;
    margin-left: calc(50.0% + -159px);
  }
  .AdminScreen > .layoutFlow > .elLicenses > * { 
  width: 318px;
  }
  
  .AdminScreen > .layoutFlow > .elLicenses > .background > .containerMinHeight {
    min-height: 460px;
  }

}

.AdminScreen .elMenuButton {
  cursor: pointer;
  border: 0.0px solid transparent;
  border-radius: 2.3px;
}

.AdminScreen .elMenuButton:focus {
  box-shadow: 0 0 5px rgba(255, 192, 6, 0.5000);
  outline: none;
}



/* Narrowest size for this element's responsive layout */
  .AdminScreen > .screenFgContainer > .foreground > .elMenuButton {
    position: absolute;
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
@media (min-width: 568px) {
  .AdminScreen > .screenFgContainer > .foreground > .elMenuButton {
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 768px) {
  .AdminScreen > .screenFgContainer > .foreground > .elMenuButton {
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
}
@media (min-width: 1024px) {
  .AdminScreen > .screenFgContainer > .foreground > .elMenuButton {
    left: 0px;
    top: 5px;
    width: 41px;
    height: 36px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AdminScreen > .screenFgContainer > .foreground > .elTheStack {
    position: absolute;
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
@media (min-width: 568px) {
  .AdminScreen > .screenFgContainer > .foreground > .elTheStack {
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
}
@media (min-width: 768px) {
  .AdminScreen > .screenFgContainer > .foreground > .elTheStack {
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
}
@media (min-width: 1024px) {
  .AdminScreen > .screenFgContainer > .foreground > .elTheStack {
    left: calc(50.0% + -75px);
    top: 13px;
    width: 150px;
    height: 22px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AdminScreen > .screenFgContainer > .foreground > .elLogo {
    position: absolute;
    left: calc(50.0% + -110px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
@media (min-width: 568px) {
  .AdminScreen > .screenFgContainer > .foreground > .elLogo {
    left: calc(50.0% + -110px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 768px) {
  .AdminScreen > .screenFgContainer > .foreground > .elLogo {
    left: calc(50.0% + -109px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
}
@media (min-width: 1024px) {
  .AdminScreen > .screenFgContainer > .foreground > .elLogo {
    left: calc(50.0% + -109px);
    top: 10px;
    width: 30px;
    height: 30px;
  }
}


/* Narrowest size for this element's responsive layout */
  .AdminScreen > .screenFgContainer > .foreground > .elProgress {
    position: absolute;
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
@media (min-width: 568px) {
  .AdminScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 768px) {
  .AdminScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
}
@media (min-width: 1024px) {
  .AdminScreen > .screenFgContainer > .foreground > .elProgress {
    left: calc(50.0% + 78px);
    top: 0px;
    width: 48px;
    height: 48px;
  }
}

/* This component has a fixed-size layout */
  .Subscriptions3 {
    min-height: 50px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .Subscriptions3 > .background > .containerMinHeight {
    min-height: 50px;
  }


  .Subscriptions3 > .layoutFlow > .elSubscription {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 260px;
    margin-left: 2px;
  }
  .Subscriptions3 > .layoutFlow > .elSubscription > * { 
  width: 260px;
  }
  


  .Subscriptions3 > .layoutFlow > .elProfileLimit {
    overflow: hidden;
    position: relative;
    margin-top: 4px;
    width: 120px;
    margin-left: 2px;
  }
  .Subscriptions3 > .layoutFlow > .elProfileLimit > * { 
  width: 120px;
  }
  


  .Subscriptions3 > .layoutFlow > .flowRow > .elActivationLimit {
    overflow: hidden;
    position: absolute;
    margin-top: -20px;
    width: 140px;
    margin-left: 130px;
  }
  .Subscriptions3 > .layoutFlow > .flowRow > .elActivationLimit > * { 
  width: 140px;
  }
  
  .flowRow_Subscriptions3_elActivationLimit_632570 { 
  }
  


  .Subscriptions3 > .layoutFlow > .elOrder {
    overflow: hidden;
    position: relative;
    margin-top: 4px;
    width: 120px;
    margin-left: 2px;
  }
  .Subscriptions3 > .layoutFlow > .elOrder > * { 
  width: 120px;
  }
  


  .Subscriptions3 > .layoutFlow > .flowRow > .elName {
    overflow: hidden;
    position: absolute;
    margin-top: -14px;
    width: 170px;
    margin-left: 130px;
  }
  .Subscriptions3 > .layoutFlow > .flowRow > .elName > * { 
  width: 170px;
  }
  
  .flowRow_Subscriptions3_elName_45612 { 
  }
  


  .Subscriptions3 > .layoutFlow > .elCard {
    position: relative;
    margin-top: 2px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .Subscriptions3 > .layoutFlow > .elCard > * { 
  width: 320px;
  height: 2px;
  }
  

.Subscriptions3 .elFab {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.Subscriptions3 .elFab:focus {
  box-shadow: 0 0 6px rgba(255, 192, 6, 0.2500);
  outline: none;
}



  .Subscriptions3 > .foreground > .elFab {
    position: absolute;
    right: 0px;
    top: 10px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .SubscriptionEditor {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .SubscriptionEditor > .layoutFlow > .elTitle {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .SubscriptionEditor > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  


  .SubscriptionEditor > .layoutFlow > .elEditor {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .SubscriptionEditor > .layoutFlow > .elEditor > * { 
  width: 320px;
  height: 140px;
  }
  


  .SubscriptionEditor > .layoutFlow > .flowRow > .elHelpkey {
    overflow: hidden;
    position: absolute;
    margin-top: -137px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elHelpkey > * { 
  width: 320px;
  }
  
  .flowRow_SubscriptionEditor_elHelpkey_175891 { 
  }
  


  .SubscriptionEditor > .layoutFlow > .flowRow > .elProfilesLabel {
    overflow: hidden;
    position: absolute;
    top: -116px;
    width: 50px;
    margin-left: calc(50.0% + 5px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elProfilesLabel > * { 
  width: 50px;
  }
  


  .SubscriptionEditor > .layoutFlow > .flowRow > .elActivationsLabel {
    overflow: hidden;
    position: absolute;
    top: -116px;
    width: 60px;
    margin-left: calc(50.0% + 55px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elActivationsLabel > * { 
  width: 60px;
  }
  


  .SubscriptionEditor > .layoutFlow > .flowRow > .elSubscriptionsLabel {
    overflow: hidden;
    position: absolute;
    top: -116px;
    width: 150px;
    margin-left: calc(50.0% + -150px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elSubscriptionsLabel > * { 
  width: 150px;
  }
  


.SubscriptionEditor > .layoutFlow > .flowRow > .elSubscriptionNumber input {
  color: #212121;
}

.SubscriptionEditor > .layoutFlow > .flowRow > .elSubscriptionNumber input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.SubscriptionEditor > .layoutFlow > .flowRow > .elSubscriptionNumber input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .SubscriptionEditor > .layoutFlow > .flowRow > .elSubscriptionNumber {
    position: absolute;
    top: -103px;
    width: 150px;
    margin-left: calc(50.0% + -150px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elSubscriptionNumber > * { 
  width: 150px;
  height: 30px;
  }
  


.SubscriptionEditor > .layoutFlow > .flowRow > .elProfileLimit input {
  color: #212121;
}

.SubscriptionEditor > .layoutFlow > .flowRow > .elProfileLimit input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.SubscriptionEditor > .layoutFlow > .flowRow > .elProfileLimit input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .SubscriptionEditor > .layoutFlow > .flowRow > .elProfileLimit {
    position: absolute;
    top: -103px;
    width: 50px;
    margin-left: calc(50.0% + 5px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elProfileLimit > * { 
  width: 50px;
  height: 30px;
  }
  


.SubscriptionEditor > .layoutFlow > .flowRow > .elActivationLimit input {
  color: #212121;
}

.SubscriptionEditor > .layoutFlow > .flowRow > .elActivationLimit input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.SubscriptionEditor > .layoutFlow > .flowRow > .elActivationLimit input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .SubscriptionEditor > .layoutFlow > .flowRow > .elActivationLimit {
    position: absolute;
    top: -103px;
    width: 50px;
    margin-left: calc(50.0% + 60px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elActivationLimit > * { 
  width: 50px;
  height: 30px;
  }
  


  .SubscriptionEditor > .layoutFlow > .flowRow > .elOrderLabel {
    overflow: hidden;
    position: absolute;
    top: -68px;
    width: 100px;
    margin-left: calc(50.0% + -150px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elOrderLabel > * { 
  width: 100px;
  }
  


.SubscriptionEditor > .layoutFlow > .flowRow > .elOrder input {
  color: #212121;
}

.SubscriptionEditor > .layoutFlow > .flowRow > .elOrder input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.SubscriptionEditor > .layoutFlow > .flowRow > .elOrder input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .SubscriptionEditor > .layoutFlow > .flowRow > .elOrder {
    position: absolute;
    top: -55px;
    width: 100px;
    margin-left: calc(50.0% + -150px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elOrder > * { 
  width: 100px;
  height: 30px;
  }
  


  .SubscriptionEditor > .layoutFlow > .flowRow > .elNameLabel {
    overflow: hidden;
    position: absolute;
    top: -68px;
    width: 150px;
    margin-left: calc(50.0% + -40px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elNameLabel > * { 
  width: 150px;
  }
  


.SubscriptionEditor > .layoutFlow > .flowRow > .elName input {
  color: #212121;
}

.SubscriptionEditor > .layoutFlow > .flowRow > .elName input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.SubscriptionEditor > .layoutFlow > .flowRow > .elName input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .SubscriptionEditor > .layoutFlow > .flowRow > .elName {
    position: absolute;
    top: -55px;
    width: 150px;
    margin-left: calc(50.0% + -40px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elName > * { 
  width: 150px;
  height: 30px;
  }
  

.SubscriptionEditor .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.SubscriptionEditor .elButton button:focus {
  box-shadow: 0 0 6px rgba(255, 192, 6, 0.2500);
  outline: none;
}



  .SubscriptionEditor > .layoutFlow > .flowRow > .elButton {
    position: absolute;
    top: -103px;
    width: 30px;
    margin-left: calc(50.0% + 120px);
  }
  .SubscriptionEditor > .layoutFlow > .flowRow > .elButton > * { 
  width: 30px;
  height: 30px;
  }
  


  .SubscriptionEditor > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: -20px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .SubscriptionEditor > .layoutFlow > .elValidator > * { 
    width: 100%;
  }
  

.SubscriptionEditor .elList li {
  margin-bottom: 5px;
}



  .SubscriptionEditor > .layoutFlow > .elList {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .SubscriptionEditor > .layoutFlow > .elList > * { 
  width: 320px;
  }
  

/* This component has a fixed-size layout */
  .SerNumEditor {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .SerNumEditor > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .SerNumEditor > .layoutFlow > .elText > * { 
    width: 100%;
  }
  


  .SerNumEditor > .layoutFlow > .elCard {
    position: relative;
    margin-top: 4px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .SerNumEditor > .layoutFlow > .elCard > * { 
  width: 320px;
  height: 120px;
  }
  


  .SerNumEditor > .layoutFlow > .flowRow > .elText2 {
    overflow: hidden;
    position: absolute;
    margin-top: -116px;
    width: 50px;
    margin-left: calc(50.0% + 5px);
  }
  .SerNumEditor > .layoutFlow > .flowRow > .elText2 > * { 
  width: 50px;
  }
  
  .flowRow_SerNumEditor_elText2_768484 { 
  }
  


  .SerNumEditor > .layoutFlow > .flowRow > .elTextCopy {
    overflow: hidden;
    position: absolute;
    top: -116px;
    width: 60px;
    margin-left: calc(50.0% + 55px);
  }
  .SerNumEditor > .layoutFlow > .flowRow > .elTextCopy > * { 
  width: 60px;
  }
  


  .SerNumEditor > .layoutFlow > .flowRow > .elTextCopy2 {
    overflow: hidden;
    position: absolute;
    top: -116px;
    width: 150px;
    margin-left: calc(50.0% + -150px);
  }
  .SerNumEditor > .layoutFlow > .flowRow > .elTextCopy2 > * { 
  width: 150px;
  }
  


.SerNumEditor > .layoutFlow > .flowRow > .elSerialNumber input {
  color: #212121;
}

.SerNumEditor > .layoutFlow > .flowRow > .elSerialNumber input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.SerNumEditor > .layoutFlow > .flowRow > .elSerialNumber input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .SerNumEditor > .layoutFlow > .flowRow > .elSerialNumber {
    position: absolute;
    top: -103px;
    width: 150px;
    margin-left: calc(50.0% + -150px);
  }
  .SerNumEditor > .layoutFlow > .flowRow > .elSerialNumber > * { 
  width: 150px;
  height: 30px;
  }
  


.SerNumEditor > .layoutFlow > .flowRow > .elProfileLimit input {
  color: #212121;
}

.SerNumEditor > .layoutFlow > .flowRow > .elProfileLimit input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.SerNumEditor > .layoutFlow > .flowRow > .elProfileLimit input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .SerNumEditor > .layoutFlow > .flowRow > .elProfileLimit {
    position: absolute;
    top: -103px;
    width: 50px;
    margin-left: calc(50.0% + 5px);
  }
  .SerNumEditor > .layoutFlow > .flowRow > .elProfileLimit > * { 
  width: 50px;
  height: 30px;
  }
  


.SerNumEditor > .layoutFlow > .flowRow > .elActivationLimit input {
  color: #212121;
}

.SerNumEditor > .layoutFlow > .flowRow > .elActivationLimit input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.SerNumEditor > .layoutFlow > .flowRow > .elActivationLimit input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .SerNumEditor > .layoutFlow > .flowRow > .elActivationLimit {
    position: absolute;
    top: -103px;
    width: 50px;
    margin-left: calc(50.0% + 60px);
  }
  .SerNumEditor > .layoutFlow > .flowRow > .elActivationLimit > * { 
  width: 50px;
  height: 30px;
  }
  


  .SerNumEditor > .layoutFlow > .flowRow > .elOrderLabel {
    overflow: hidden;
    position: absolute;
    top: -68px;
    width: 100px;
    margin-left: calc(50.0% + -150px);
  }
  .SerNumEditor > .layoutFlow > .flowRow > .elOrderLabel > * { 
  width: 100px;
  }
  


.SerNumEditor > .layoutFlow > .flowRow > .elOrder input {
  color: #212121;
}

.SerNumEditor > .layoutFlow > .flowRow > .elOrder input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.SerNumEditor > .layoutFlow > .flowRow > .elOrder input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .SerNumEditor > .layoutFlow > .flowRow > .elOrder {
    position: absolute;
    top: -55px;
    width: 100px;
    margin-left: calc(50.0% + -150px);
  }
  .SerNumEditor > .layoutFlow > .flowRow > .elOrder > * { 
  width: 100px;
  height: 30px;
  }
  


  .SerNumEditor > .layoutFlow > .flowRow > .elNameLabel {
    overflow: hidden;
    position: absolute;
    top: -68px;
    width: 150px;
    margin-left: calc(50.0% + -40px);
  }
  .SerNumEditor > .layoutFlow > .flowRow > .elNameLabel > * { 
  width: 150px;
  }
  


.SerNumEditor > .layoutFlow > .flowRow > .elName input {
  color: #212121;
}

.SerNumEditor > .layoutFlow > .flowRow > .elName input::input-placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

.SerNumEditor > .layoutFlow > .flowRow > .elName input::placeholder {
  color: rgba(33, 33, 33, 0.5000);
}

  .SerNumEditor > .layoutFlow > .flowRow > .elName {
    position: absolute;
    top: -55px;
    width: 150px;
    margin-left: calc(50.0% + -40px);
  }
  .SerNumEditor > .layoutFlow > .flowRow > .elName > * { 
  width: 150px;
  height: 30px;
  }
  

.SerNumEditor .elButton button {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.SerNumEditor .elButton button:focus {
  box-shadow: 0 0 6px rgba(255, 192, 6, 0.2500);
  outline: none;
}



  .SerNumEditor > .layoutFlow > .flowRow > .elButton {
    position: absolute;
    top: -103px;
    width: 30px;
    margin-left: calc(50.0% + 120px);
  }
  .SerNumEditor > .layoutFlow > .flowRow > .elButton > * { 
  width: 30px;
  height: 30px;
  }
  


  .SerNumEditor > .layoutFlow > .elValidator {
    overflow: hidden;
    position: relative;
    margin-top: -20px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .SerNumEditor > .layoutFlow > .elValidator > * { 
    width: 100%;
  }
  

.SerNumEditor .elList li {
  margin-bottom: 5px;
}



  .SerNumEditor > .layoutFlow > .elList {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .SerNumEditor > .layoutFlow > .elList > * { 
  width: 320px;
  }
  

/* This component has a fixed-size layout */
  .SerialNumbers2 {
    min-height: 50px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 300px */
    height: 100%;
  }
  .SerialNumbers2 > .background > .containerMinHeight {
    min-height: 50px;
  }


  .SerialNumbers2 > .layoutFlow > .elSubscription {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 260px;
    margin-left: 2px;
  }
  .SerialNumbers2 > .layoutFlow > .elSubscription > * { 
  width: 260px;
  }
  


  .SerialNumbers2 > .layoutFlow > .elProfileLimit {
    overflow: hidden;
    position: relative;
    margin-top: 4px;
    width: 120px;
    margin-left: 2px;
  }
  .SerialNumbers2 > .layoutFlow > .elProfileLimit > * { 
  width: 120px;
  }
  


  .SerialNumbers2 > .layoutFlow > .flowRow > .elActivationLimit {
    overflow: hidden;
    position: absolute;
    margin-top: -20px;
    width: 140px;
    margin-left: 130px;
  }
  .SerialNumbers2 > .layoutFlow > .flowRow > .elActivationLimit > * { 
  width: 140px;
  }
  
  .flowRow_SerialNumbers2_elActivationLimit_808392 { 
  }
  


  .SerialNumbers2 > .layoutFlow > .elOrder {
    overflow: hidden;
    position: relative;
    margin-top: 4px;
    width: 120px;
    margin-left: 2px;
  }
  .SerialNumbers2 > .layoutFlow > .elOrder > * { 
  width: 120px;
  }
  


  .SerialNumbers2 > .layoutFlow > .flowRow > .elName {
    overflow: hidden;
    position: absolute;
    margin-top: -14px;
    width: 170px;
    margin-left: 130px;
  }
  .SerialNumbers2 > .layoutFlow > .flowRow > .elName > * { 
  width: 170px;
  }
  
  .flowRow_SerialNumbers2_elName_578901 { 
  }
  


  .SerialNumbers2 > .layoutFlow > .elCard {
    position: relative;
    margin-top: 2px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .SerialNumbers2 > .layoutFlow > .elCard > * { 
  width: 320px;
  height: 2px;
  }
  

.SerialNumbers2 .elFab {
  cursor: pointer;
  border: 0.0px solid transparent;
}

.SerialNumbers2 .elFab:focus {
  box-shadow: 0 0 6px rgba(255, 192, 6, 0.2500);
  outline: none;
}



  .SerialNumbers2 > .foreground > .elFab {
    position: absolute;
    right: 0px;
    top: 10px;
    width: 30px;
    height: 30px;
  }

/* This component has a fixed-size layout */
  .TabItem {
    min-height: 34px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 91px */
    height: 100%;
  }
  .TabItem > .background > .containerMinHeight {
    min-height: 34px;
  }


  .TabItem > .foreground > .state0_elTitle {
    overflow: hidden;
    position: absolute;
    left: 4px;
    top: 4px;
    width: 82px;
    height: 28px;
  }

/* This component has a fixed-size layout */
  .TabItem {
    min-height: 34px;  /* This component has a fixed size */
    overflow: hidden;
    width: 100%; /* This component was designed using a width of 91px */
    height: 100%;
  }
  .TabItem > .background > .containerMinHeight {
    min-height: 34px;
  }


  .TabItem > .foreground > .state1_elTitle {
    overflow: hidden;
    position: absolute;
    left: 4px;
    top: 4px;
    width: 82px;
    height: 28px;
  }


  .TabItem > .foreground > .state1_elSelectionMarker {
    position: absolute;
    left: 0px;
    top: 32px;
    width: 91px;
    height: 2px;
  }

/* This component has a fixed-size layout */
  .Licenses {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }

.Licenses .elSubscriptions {
  overflow: hidden;
}

.Licenses .elSubscriptions.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Licenses > .layoutFlow > .elSubscriptions {
    position: relative;
    margin-top: 0px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .Licenses > .layoutFlow > .elSubscriptions > * { 
  width: 320px;
  }
  

.Licenses .elSerialNumbers {
  overflow: hidden;
}

.Licenses .elSerialNumbers.hasNestedComps > div {
  overflow: hidden;
  position: relative;
}



  .Licenses > .layoutFlow > .elSerialNumbers {
    position: relative;
    margin-top: 20px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .Licenses > .layoutFlow > .elSerialNumbers > * { 
  width: 320px;
  }
  

/* This component has a fixed-size layout */
  .Activated {
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }


  .Activated > .layoutFlow > .elTitle {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Activated > .layoutFlow > .elTitle > * { 
    width: 100%;
  }
  

.Activated .elList li {
  margin-bottom: 5px;
}



  .Activated > .layoutFlow > .elList {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .Activated > .layoutFlow > .elList > * { 
  width: 320px;
  }
  


  .Activated > .layoutFlow > .elText {
    overflow: hidden;
    position: relative;
    margin-top: 10px;
    margin-left: 0px;
    margin-right: 0px;
    width: calc(100.0% - 0px);
  }
  .Activated > .layoutFlow > .elText > * { 
    width: 100%;
  }
  

.Activated .elListCopy li {
  margin-bottom: 5px;
}



  .Activated > .layoutFlow > .elListCopy {
    position: relative;
    margin-top: 10px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .Activated > .layoutFlow > .elListCopy > * { 
  width: 320px;
  }
  

/* This component has a fixed-size layout */
  .SubAct2 {
    min-height: 50px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .SubAct2 > .background > .containerMinHeight {
    min-height: 50px;
  }


  .SubAct2 > .layoutFlow > .elLatest {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .SubAct2 > .layoutFlow > .elLatest > * { 
  margin-left: auto;
  width: 150px;
  margin-right: 2px;
  }
  


  .SubAct2 > .layoutFlow > .flowRow > .elNote {
    overflow: hidden;
    position: absolute;
    margin-top: -10px;
    width: 300px;
    margin-left: 2px;
  }
  .SubAct2 > .layoutFlow > .flowRow > .elNote > * { 
  width: 300px;
  }
  
  .flowRow_SubAct2_elNote_571834 { 
  }
  


  .SubAct2 > .layoutFlow > .elSubscription {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 2px;
  }
  .SubAct2 > .layoutFlow > .elSubscription > * { 
  width: 150px;
  }
  


  .SubAct2 > .layoutFlow > .flowRow > .elSubscriptionCopy {
    overflow: hidden;
    position: absolute;
    margin-top: -22px;
    width: 100%;
  }
  .SubAct2 > .layoutFlow > .flowRow > .elSubscriptionCopy > * { 
  margin-left: auto;
  width: 150px;
  margin-right: 2px;
  }
  
  .flowRow_SubAct2_elSubscriptionCopy_62458 { 
  }
  


  .SubAct2 > .layoutFlow > .elOrder {
    overflow: hidden;
    position: relative;
    margin-top: 2px;
    width: 150px;
    margin-left: 2px;
  }
  .SubAct2 > .layoutFlow > .elOrder > * { 
  width: 150px;
  }
  


  .SubAct2 > .layoutFlow > .flowRow > .elName {
    overflow: hidden;
    position: absolute;
    margin-top: -12px;
    width: 170px;
    margin-left: 150px;
  }
  .SubAct2 > .layoutFlow > .flowRow > .elName > * { 
  width: 170px;
  }
  
  .flowRow_SubAct2_elName_209294 { 
  }
  


  .SubAct2 > .layoutFlow > .elBottomLine {
    position: relative;
    margin-top: 2px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .SubAct2 > .layoutFlow > .elBottomLine > * { 
  width: 320px;
  height: 2px;
  }
  


  .SubAct2 > .layoutFlow > .flowRow > .elUid {
    overflow: hidden;
    position: absolute;
    margin-top: -24px;
    width: 100%;
  }
  .SubAct2 > .layoutFlow > .flowRow > .elUid > * { 
  margin-left: auto;
  width: 71px;
  margin-right: 0px;
  }
  
  .flowRow_SubAct2_elUid_419329 { 
  }
  


  .SubAct2 > .layoutFlow > .flowRow > .elActivations {
    overflow: hidden;
    position: absolute;
    top: -24px;
    width: 100%;
  }
  .SubAct2 > .layoutFlow > .flowRow > .elActivations > * { 
  margin-left: auto;
  width: 90px;
  margin-right: 72px;
  }
  


  .SubAct2 > .layoutFlow > .flowRow > .elActivationsCopy {
    overflow: hidden;
    position: absolute;
    top: -24px;
    width: 100%;
  }
  .SubAct2 > .layoutFlow > .flowRow > .elActivationsCopy > * { 
  margin-left: auto;
  width: 74px;
  margin-right: 164px;
  }
  

/* This component has a fixed-size layout */
  .SNAct2 {
    min-height: 50px;  /* Minimum height to ensure layout flow is visible */
    width: 100%; /* This component was designed using a width of 320px */
    height: 100%;
  }
  .SNAct2 > .background > .containerMinHeight {
    min-height: 50px;
  }


  .SNAct2 > .layoutFlow > .elLatest {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 100%;
  }
  .SNAct2 > .layoutFlow > .elLatest > * { 
  margin-left: auto;
  width: 150px;
  margin-right: 2px;
  }
  


  .SNAct2 > .layoutFlow > .flowRow > .elNote {
    overflow: hidden;
    position: absolute;
    margin-top: -10px;
    width: 300px;
    margin-left: 2px;
  }
  .SNAct2 > .layoutFlow > .flowRow > .elNote > * { 
  width: 300px;
  }
  
  .flowRow_SNAct2_elNote_321163 { 
  }
  


  .SNAct2 > .layoutFlow > .elSubscription {
    overflow: hidden;
    position: relative;
    margin-top: 0px;
    width: 150px;
    margin-left: 2px;
  }
  .SNAct2 > .layoutFlow > .elSubscription > * { 
  width: 150px;
  }
  


  .SNAct2 > .layoutFlow > .flowRow > .elSubscriptionCopy {
    overflow: hidden;
    position: absolute;
    margin-top: -18px;
    width: 100%;
  }
  .SNAct2 > .layoutFlow > .flowRow > .elSubscriptionCopy > * { 
  margin-left: auto;
  width: 150px;
  margin-right: 2px;
  }
  
  .flowRow_SNAct2_elSubscriptionCopy_853095 { 
  }
  


  .SNAct2 > .layoutFlow > .flowRow > .elUid {
    overflow: hidden;
    position: absolute;
    top: -4px;
    width: 100%;
  }
  .SNAct2 > .layoutFlow > .flowRow > .elUid > * { 
  margin-left: auto;
  width: 74px;
  margin-right: 0px;
  }
  


  .SNAct2 > .layoutFlow > .elOrder {
    overflow: hidden;
    position: relative;
    margin-top: 4px;
    width: 150px;
    margin-left: 2px;
  }
  .SNAct2 > .layoutFlow > .elOrder > * { 
  width: 150px;
  }
  


  .SNAct2 > .layoutFlow > .flowRow > .elName {
    overflow: hidden;
    position: absolute;
    margin-top: -12px;
    width: 170px;
    margin-left: 150px;
  }
  .SNAct2 > .layoutFlow > .flowRow > .elName > * { 
  width: 170px;
  }
  
  .flowRow_SNAct2_elName_824724 { 
  }
  


  .SNAct2 > .layoutFlow > .elCard {
    position: relative;
    margin-top: 2px;
    width: 320px;
    margin-left: calc(50.0% + -160px);
  }
  .SNAct2 > .layoutFlow > .elCard > * { 
  width: 320px;
  height: 2px;
  }
  


  .SNAct2 > .layoutFlow > .flowRow > .elUidCopy {
    overflow: hidden;
    position: absolute;
    margin-top: -25px;
    width: 100%;
  }
  .SNAct2 > .layoutFlow > .flowRow > .elUidCopy > * { 
  margin-left: auto;
  width: 88px;
  margin-right: 78px;
  }
  
  .flowRow_SNAct2_elUidCopy_204824 { 
  }
  


  .SNAct2 > .layoutFlow > .flowRow > .elUidCopy2 {
    overflow: hidden;
    position: absolute;
    top: -25px;
    width: 100%;
  }
  .SNAct2 > .layoutFlow > .flowRow > .elUidCopy2 > * { 
  margin-left: auto;
  width: 68px;
  margin-right: 172px;
  }
  

